import React, {useEffect, useState} from 'react';
import './App.css';
import {msalInstance, withAuth} from "./hoc/Auth";
import {hot} from 'react-hot-loader';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    // useHistory,
    useParams,
    // useLocation
} from 'react-router-dom';
import {graph} from "@pnp/graph";
import "@pnp/graph/users";
import {User} from '@microsoft/microsoft-graph-types';
import {sp} from "@pnp/sp";
import {Web} from "@pnp/sp/webs";
import "@pnp/sp/folders";


interface IState {
    user: User;
}

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <Router>
                    <div>
                        {/*<ul>*/}
                        {/*    <li>*/}
                        {/*        <Link to="/">Home</Link>*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*        <Link to="/projects">Projects</Link>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}

                        <Switch>
                            <Route path="/projects/:id">
                                <Home/>
                            </Route>
                        </Switch>
                    </div>
                </Router>
            </header>
        </div>

    )
}

// function useQuery() {
//     return new URLSearchParams(useLocation().search);
// }

function Home() {
    // let history = useHistory();
    let {id}: any = useParams();

    const [internal, setInternal] = useState(0)
    const [name, setName] = useState('')
    const [message, setMessage] = useState('') as any;
    const [loading, setLoading] = useState(false)

    // commercial folder structure
    const commercialSubfolders = [
        {
            name: '03 Proposal',
            subfolders: [
                {
                    name: '01 Proposal',
                    subfolders: null
                }
            ]
        },
        {
            name: '04 Contract',
            subfolders: [
                {
                    name: '01 Award Letter',
                    subfolders: null
                }
            ]
        },
        {
            name: '06 Comms',
            subfolders: [
                {
                    name: '01 Client\'s Feedback',
                    subfolders: null
                }
            ]
        },
        {
            name: '07 Financial Mgt',
            subfolders: [
                {
                    name: '01 Project Finance',
                    subfolders: [
                        {
                            name: '01 DMS of Invoices',
                            subfolders: null
                        }
                    ]
                }
            ]
        },
    ]

    // PD folders structure
    const pdSubfolders = [
        {
            name: '01 Project Planning',
            subfolders: [
                {
                    name: '01 Project Brief & SoW',
                    subfolders: null
                },
                {
                    name: '02 Programme',
                    subfolders: null
                },
                {
                    name: '03 Third Parties',
                    subfolders: null
                },
                {
                    name: '04 Others',
                    subfolders: null
                }
            ]
        },
        {
            name: '02 QHSSE',
            subfolders: [
                {
                    name: '01 QA',
                    subfolders: [
                        {
                            name: '01 DMS of QA',
                            subfolders: null
                        }
                    ]
                },
                {
                    name: '02 HSE',
                    subfolders: null
                },
            ]
        },
        {
            name: '03 Operations',
            subfolders: [
                {
                    name: '01 Existing Data',
                    subfolders: null
                },
                {
                    name: '02 Fieldworks',
                    subfolders: null
                },
                {
                    name: '03 Drawings',
                    subfolders: [
                        {
                            name: '01 CAD',
                            subfolders: null
                        },
                        {
                            name: '02 BIM',
                            subfolders: null
                        }
                    ]
                },
                {
                    name: '04 Design Calcs',
                    subfolders: null
                },
            ]
        },
        {
            name: '04 Comms',
            subfolders: [
                {
                    name: '01 Meeting Minutes',
                    subfolders: null
                },
                {
                    name: '02 Progress Report',
                    subfolders: null
                },
                {
                    name: '03 Letters & Memos',
                    subfolders: null
                },
                {
                    name: '04 DMS Incoming',
                    subfolders: null
                },
                {
                    name: '05 DMS Outgoing',
                    subfolders: null
                },
                {
                    name: '06 DMS Email',
                    subfolders: null
                },
                {
                    name: '07 Contacts',
                    subfolders: null
                },
                {
                    name: '08 Tender',
                    subfolders: null
                },
                {
                    name: '09 Construction',
                    subfolders: null
                },
            ]
        },
        {
            name: '05 Deliverables',
            subfolders: [
                {
                    name: '01 Final Design',
                    subfolders: null
                },
                {
                    name: '02 Final Drawings',
                    subfolders: null
                },
                {
                    name: '03 Reports',
                    subfolders: null
                },
            ]
        }
    ]

    const handleChange = (e: any) => {
        setInternal(e.target.value)
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const confirm = window.confirm('Confirm the input?')
        // if (confirm) history.push(`/projects/${id}?owned=${value}`)

        if (confirm) {
            setLoading(true)
            let message = ''
            try {
                const getSubfolders = async (pdRootFolder: any, folder: any, parent: any,) => {
                    const subfolders = folder.subfolders
                    if (subfolders) {
                        let path = ''
                        path += parent.name
                        for (const subfolder of subfolders) {
                            const realPath = path ? `${path}/${folder.name}` : folder.name
                            const spFolder = await web.getFolderByServerRelativeUrl(`${pdRootFolder}/${realPath}`);
                            await spFolder.folders.add(subfolder.name) // add folder
                            message = `Folder '${realPath}': Added new subfolder '${subfolder.name}' \n`
                            setMessage((prev: string) => [prev, message])
                            await getSubfolders(pdRootFolder, subfolder, folder)
                        }
                    }
                }


                // create web instance
                const web = Web(`https://fugro.sharepoint.com/sites/p365_${id}/`);

                const sharedDocumentFolder = await web.getFolderByServerRelativeUrl(`/sites/p365_${id}/Shared Documents/`)

                const subfolders = await sharedDocumentFolder.folders()

                for (const subfolder of subfolders) {
                    // Commercial folder, only edit this folder when project is internal
                    if (internal && subfolder.Name === 'Commercial') {
                        message = `*** Inside Commercial Folder *** \n`
                        setMessage((prev: string) => [prev, message])
                        for (const commercialSubfolder of commercialSubfolders) {
                            const folders = await web.getFolderByServerRelativeUrl(`${subfolder.ServerRelativeUrl}/${commercialSubfolder.name}`).folders.get()
                            for (const folder of folders) {
                                await web.getFolderByServerRelativeUrl(`${subfolder.ServerRelativeUrl}/${commercialSubfolder.name}`).folders.getByName(folder.Name).delete()
                            }
                            await getSubfolders(subfolder.ServerRelativeUrl, commercialSubfolder, {name: ''})
                        }
                        setMessage((prev: string) => [prev, '\n'])
                    }

                    // PD folder
                    if (subfolder.Name === `PD - ${id}`) {
                        message = `*** Inside Folder ${subfolder.Name} *** \n`
                        setMessage((prev: string) => [prev, message])
                        for (const pdSubfolder of pdSubfolders) {
                            const folders = await web.getFolderByServerRelativeUrl(`${subfolder.ServerRelativeUrl}/${pdSubfolder.name}`).folders.get()
                            for (const folder of folders) {
                                await web.getFolderByServerRelativeUrl(`${subfolder.ServerRelativeUrl}/${pdSubfolder.name}`).folders.getByName(folder.Name).delete()
                            }
                            await getSubfolders(subfolder.ServerRelativeUrl, pdSubfolder, {name: ''})
                        }
                        setMessage((prev: string) => [prev, '\n'])
                    }
                }

                message = `All Folders created successfully! \n`
                setMessage((prev: string) => [prev, message])
            } catch (e) {
                console.log(e)
                message = `Something went wrong. Please try again later. \n ${e}`
                setMessage((prev: string) => [prev, message])
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        const user = msalInstance.getAccount()
        setName(user.name)
    }, [])


    return (
        <div className="App">
            <header className="App-header">
                <h1> Home </h1>
                <p>Logged in as: {name}</p>
                <p>Project Number: {id}</p>
                <form onSubmit={handleSubmit}>
                    <label>
                        Internal:
                        <select value={internal} onChange={handleChange}>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                        </select>
                    </label>
                    <br/>
                    <input type="submit" value="Submit" disabled={loading}/>
                </form>
                <p className="new-line">{message}</p>
            </header>
        </div>
    );
}

export default hot(module)(withAuth(App));
